import Web3 from "web3";
import axios from "axios";
import Decimal from "decimal.js";

const abi = require("@/contract/abi.json");
const web3 = new Web3("https://bsc-dataseed1.binance.org:443");
/**
 * Service to call Web3 query
 */
const Web3service = {
  async getTokenLpAddressWeb3(token, lpV2) {
    if (localStorage[token + "_pair_" + lpV2]) {
      return localStorage[token + "_pair_" + lpV2];
    }
    try {
      let factory_address;

      if (lpV2) {
        factory_address = "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73";
      } else {
        factory_address = "0xBCfCcbde45cE874adCB698cC183deBcF17952812";
      }

      let factory_contract = await new web3.eth.Contract(abi, factory_address);

      let pair = await factory_contract.methods
        .getPair(token, "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c")
        .call();
      localStorage.setItem(token + "_pair_" + lpV2, pair);

      return pair;
    } catch (e) {
      console.log(e);
    }
  },

  async getTokenPriceAndPairLp({
                                 token_address,
                                 token_lp_address,
                                 pair_address,
                                 pair_decimals,
                                 pair_usd_price,
                               }) {
    try {
      let token_lp_balance = await this.getTokenBalanceWeb3(
        token_address,
        token_lp_address,
        token_decimals,
      );
      let pair_lp_balance = await this.getTokenBalanceWeb3(
        pair_address,
        token_lp_address,
        pair_decimals,
      );

      let token_decimals = await this.getDecimals(token_address);

      let price_per_token = pair_lp_balance
        .dividedBy(token_lp_balance)
        .times(pair_usd_price);

      return {
        price_per_token,
        token_lp_balance,
        pair_lp_balance,
        token_lp_address,
      };
    } catch (error) {
      console.log(error);
      return undefined;
    }
  },

  async getTokenBalanceWeb3(token, wallet, decimals) {
    try {
      var tokenInst = new web3.eth.Contract(abi, token);
      let val = await tokenInst.methods.balanceOf(wallet).call();
      return this.convertDecimal(val, decimals).toFixed(3);
    } catch (e) {
      console.log(e);
    }
  },
  async getERC20TransactionsByAddress({
                                        token_address,
                                        fromBlock,
                                        lp_address,
                                        token_decimals,
                                      }) {
    try {
      lp_address = lp_address.toLowerCase();

      let currentBlockNumber = await web3.eth.getBlockNumber();

      if (!fromBlock) fromBlock = currentBlockNumber - 100;

      let token_contract = new web3.eth.Contract(abi, token_address);

      let transferEvents = await token_contract.getPastEvents("Transfer", {
        fromBlock,
        filter: {
          isError: 0,
          txreceipt_status: 1,
        },
      });

      transferEvents = await transferEvents
        .sort((evOne, evTwo) => evOne.blockNumber - evTwo.blockNumber)
        .map(({ blockNumber, transactionHash, returnValues }) => {
          return {
            transactionHash,
            // confirmations: currentBlockNumber - blockNumber,
            blockNumber: blockNumber,
            tokens: new Decimal(returnValues._value).times(
              Decimal.pow(10, -token_decimals),
            ),
            type:
              returnValues._from.toLowerCase() == lp_address ? "buy" : "sell",
          };
        });

      return transferEvents;
    } catch (error) {
      return undefined;
    }
  },
  async getTokenPastTransactions(token_address) {
    try {
      let bnb_address = "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c";
      let bnb_decimals = 18;

      // get & cache main pair address for half a day
      let main_pair = await this.getTokenLpAddressWeb3(token_address);
      let token_decimals = await this.getDecimals(token_address);
      const bnb_usd_price = await this.getBnbToUsd();

      let past_transactions = await this.getERC20TransactionsByAddress({
        token_address: token_address,
        lp_address: main_pair,
        token_decimals: token_decimals,
      });

      let token_price_and_lp = await this.getTokenPriceAndPairLp(
        token_address,
        token_decimals,
        main_pair,
        bnb_address,
        bnb_decimals,
        bnb_usd_price,
      );

      let current_price = token_price_and_lp.price_per_token;
      let token_lp_balance = token_price_and_lp.token_lp_balance;
      let bnb_lp_balance = token_price_and_lp.pair_lp_balance;

      // without timestamp
      past_transactions = past_transactions.reverse();

      // Calculate the price backwards
      let past_calulation = {
        bnb_lp_balance: new Decimal(bnb_lp_balance),
        token_lp_balance: new Decimal(token_lp_balance),
        price_per_token: new Decimal(current_price),
      };
      for (const transaction of past_transactions) {
        let transaction_calculation = this.transaction_calculate_token_price({
          transaction_type: transaction.type,
          transaction_token_amount: transaction.tokens,
          token_lp_balance: past_calulation.token_lp_balance,
          token_decimals: token_decimals,
          last_price_per_token: past_calulation.price_per_token,
          bnb_lp_balance: past_calulation.bnb_lp_balance,
          bnb_usd_price: bnb_usd_price,
        });

        transaction.price_per_token = transaction_calculation.price_per_token;
        transaction.bnb_amount = transaction_calculation.bnb_amount;
        transaction.usd_amount = transaction_calculation.usd_amount;

        past_calulation.price_per_token =
          transaction_calculation.price_per_token;
        past_calulation.bnb_lp_balance = transaction_calculation.bnb_lp_balance;
        past_calulation.token_lp_balance =
          transaction_calculation.token_lp_balance;
      }

      return past_transactions;
    } catch (error) {
      console.log("getTokenPastTransactions", "error", error);
    }
  },

  async getBnbToUsd() {
    return axios
      .get("https://api.binance.com/api/v3/avgPrice?symbol=BNBBUSD")
      .then(response => {
        return response.data.price;
      })
      .catch(err => {
        console.log(err);
      });
  },

  async getBurnt(token, decimals) {
    let burnt = await this.getTokenBalanceWeb3(
      token,
      "0x000000000000000000000000000000000000dead",
      decimals,
    );

    if (burnt == 0) {
      burnt = await this.getTokenBalanceWeb3(
        token,
        "0x0000000000000000000000000000000000000001",
        decimals,
      );
    }

    return burnt;
  },

  async getTokenTotalSupply(token, decimals) {
    try {
      var tokenInst = new web3.eth.Contract(abi, token);
      let supply = await tokenInst.methods.totalSupply().call();
      return this.convertDecimal(supply, decimals).toFixed();
    } catch (e) {
      console.log(e);
    }
  },

  async toChecksumAddress(token) {
    try {
      let checkSum = await web3.utils.toChecksumAddress(token);

      return checkSum;
    } catch (e) {
      console.log(e);
    }
  },

  async getName(token) {
    try {
      var tokenInst = new web3.eth.Contract(abi, token);
      return await tokenInst.methods.name().call();
    } catch (e) {
      console.log(e);
    }
  },

  async getDecimals(token) {
    try {
      var tokenInst = new web3.eth.Contract(abi, token);
      return await tokenInst.methods.decimals().call();
    } catch (e) {
      console.log(e);
    }
  },

  async getSymbol(token) {
    try {
      var tokenInst = new web3.eth.Contract(abi, token);
      return await tokenInst.methods.symbol().call();
    } catch (e) {
      console.log(e);
    }
  },

  async getTokenToBnb(pair, balance) {
    try {
      var pairContract = await new web3.eth.Contract(abi, pair);

      let token1Address = await pairContract.methods.token1().call();
      let token0Address = await pairContract.methods.token0().call();

      let token1Decimals = await this.getTokenDecimals(token1Address);
      let token0Decimals = await this.getTokenDecimals(token0Address);

      let reserves = await this.getReserves(pair);
      let reserve0 = parseFloat(
        this.convertDecimal(reserves.reserve0, token0Decimals).toFixed(),
      );
      let reserve1 = parseFloat(
        this.convertDecimal(reserves.reserve1, token1Decimals).toFixed(),
      );

      let val = 1 / (reserve1 / reserve0);

      let exchangeVal =
        reserve0 - (reserve0 * reserve1) / (parseFloat(balance) + reserve1);

      if (val > 1) {
        val = 1 / (reserve0 / reserve1);
        exchangeVal =
          reserve1 - (reserve0 * reserve1) / (parseFloat(balance) + reserve0);
      }

      if (!val) {
        val = 0;
      }

      if (!exchangeVal) {
        exchangeVal = 0;
      }

      return {
        price: val,
        exchangeVal: exchangeVal,
      };
    } catch (e) {
      console.log(e);
      return null;
    }
  },

  async getReserves(pair) {
    try {
      var pairContract = await new web3.eth.Contract(abi, pair);

      return await pairContract.methods.getReserves().call();
    } catch (e) {
      console.log(e);
    }
  },

  async checkAddress(address) {
    let valid = await web3.utils.isAddress(address);

    return valid;
  },

  convertDecimal(token_amount, token_decimal) {
    return new Decimal(token_amount).dividedBy(Decimal.pow(10, token_decimal));
  },

  async getTokenDecimals(address) {
    var contract = await new web3.eth.Contract(abi, address);

    return await contract.methods.decimals().call();
  },
};

export default Web3service;
